import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import ClanDetails from "./pages/ClanDetails";
import Login from "./pages/Login";
import { AccProvider } from "./context/OtherContext";
import { Toaster } from "react-hot-toast";
import SupportChatbot from "./components/SupportChat/SupportChatbot";


function App() {

  return (
    <AccProvider>
      <div className="">
      <Toaster />
      <SupportChatbot />
        <Router>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/' element={<Dashboard />} />
            <Route path='/clan/:id' element={<ClanDetails />} />
          </Routes>
        </Router>
      </div>
    </AccProvider>
  );
}

export default App;
